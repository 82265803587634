import React from "react";
import {graphql} from "gatsby";
import styled from "@emotion/styled";

import Layout from "../components/layout/layout";
import PageHeader from "../components/pageHeader";
import Container from "../components/container";
import ContactForm from "../components/contact/contactForm";
import ContactInfo from "../components/contact/contactInfo";
import Separator from "../components/Separator";

const Row = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 30px 0 100px;
    justify-content: space-between;
`;

export default function Contact({data}): JSX.Element {
    const {
        formSubmitButtonText,
        formNotePlaceholder,
        formNamePlaceholder,
        lastName,
        firstName,
        formEmailPlaceholder,
        formBudgetPlaceholder,
        headerTitle,
        headerDescription,
        budgetOptions,
        contactInfoTitle,
        contactInfoLocation,
        contactInfoEmail,
        contactInfoServing
    } = data.contentfulContactPage;

    const {contactLinkTitle} = data.contentfulLayout;

    return (
        <Layout title={headerTitle} hideContactFooterBanner>
            <PageHeader
                title={headerTitle}
                titleSubtext={contactLinkTitle}
                description={headerDescription.headerDescription}
            />
            <Container>
                <Separator />
                <Row>
                    <ContactForm
                        formBudgetPlaceholder={formBudgetPlaceholder}
                        formNotePlaceholder={formNotePlaceholder}
                        formEmailPlaceholder={formEmailPlaceholder}
                        formNamePlaceholder={formNamePlaceholder}
                        formFirstNamePlaceholder={firstName}
                        formLastNamePlaceholder={lastName}
                        formSubmitButtonText={formSubmitButtonText}
                        budgetOptions={budgetOptions} />
                    <ContactInfo 
                        title={contactInfoTitle} 
                        email={contactInfoEmail} 
                        location={contactInfoLocation} 
                        serving={contactInfoServing} />
                </Row>
            </Container>
        </Layout>
    );
}

export const query = graphql`
    query {
        contentfulContactPage {
            formSubmitButtonText
            formNotePlaceholder
            formNamePlaceholder
            firstName
            lastName
            formEmailPlaceholder
            formBudgetPlaceholder
            headerTitle
            headerDescription {
                headerDescription
            }
            budgetOptions
            contactInfoTitle
            contactInfoEmail
            contactInfoLocation
            contactInfoServing
        }
        contentfulLayout {
            contactLinkTitle
        }
    }
`;