import React, { useState } from "react";
import styled from "@emotion/styled";
import fetch from "isomorphic-unfetch";
import Cookies from "js-cookie";

import { trackEvent, hubspotPortalId } from '../../utils/analytics';
import BoxButton from '../BoxButton';

const FormContainer = styled.div`
    width: 100%;

    @media(min-width: ${props => props.theme.lg}px) {
        width: 60%;
    }
`;

const Form = styled.form<any>`
    label > span {
        display: none;
    }

    input, textarea, select {
        border: 1px solid ${props => props.theme.primaryColor};
        padding: 20px 20px;
        display: block;
        width: 100%;
        margin-bottom: 32px;

        &:focus {
            border-color: ${props => props.theme.secondaryColor};
            outline: none;
        }
    }
    
    textarea {
        margin-top: 10px;
        height: 226px;
        resize: vertical;
    }

    select {
        margin: 20px 0 30px;
    }
`;

const TopRow = styled.div<any>`
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    > label {
        display: block;
        margin: 10px;
        flex: 1 1 calc(50% - 20px);
    }
`;

const SubmitButton = styled.input<any>`
    background: #fff;
    border: 0;
    padding: 10px;
    font-size: 18px;
    padding: 20px 60px;
    margin-top: 40px;
    cursor: pointer;
    transition: background .3s ease-in-out, color .3s ease-in-out;
    color ${props => props.theme.secondaryColor};
    max-width: 200px;

    &:hover {
        background: ${props => props.theme.secondaryColor};
        color: #fff;
    }
`;

const SuccessMessage = styled.div<any>`
    padding: 20px;
    background: ${props => props.theme.secondaryColor};
    color: #fff;
    font-size: 18px;
    margin-bottom: 20px;
`;

const ErrorMessage = styled(SuccessMessage)`
    background: #ff5e5e;
`;

const HiddenLabel = styled.label<any>`
    display: none;

    input {
        display: none;
    }
    span {
        display: none;
    }
`;

export interface IContactFormProps {
    formSubmitButtonText: string;
    formNotePlaceholder: string;
    formNamePlaceholder: string;
    formFirstNamePlaceholder: string;
    formLastNamePlaceholder: string;
    formEmailPlaceholder: string;
    formBudgetPlaceholder: string;
    budgetOptions: Array<string>;
}

export default function ContactForm(props: IContactFormProps): JSX.Element {
    const {
        formSubmitButtonText,
        formBudgetPlaceholder,
        formEmailPlaceholder,
        formNamePlaceholder,
        formFirstNamePlaceholder,
        formLastNamePlaceholder,
        formNotePlaceholder,
        budgetOptions
    } = props;

    const [didSubmit, setSubmitted] = useState(false);
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");

    const formId = "356273bf-feff-451a-aae4-54e000a901f0";

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!email || email == "") {
            setError("Email is required!");
            return;
        }
        setError("");

        trackEvent("Contact From Submitted", {});

        const isBrowser = typeof window !== 'undefined';
        const hutk = isBrowser ? Cookies.get('hubspotutk') : null;
        const pageUri = isBrowser ? window.location.href : null;
        const pageName = isBrowser ? document.title : null;
        const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${hubspotPortalId}/${formId}`;

        const body = {
            submittedAt: Date.now(),
            fields: [
                {
                    name: 'email',
                    value: email,
                },
                {
                    name: 'firstname',
                    value: firstName,
                },
                {
                    name: 'lastname',
                    value: lastName,
                },
                {
                    name: 'message',
                    value: message,
                },
            ],
            context: {
                hutk,
                pageUri,
                pageName,
            }
        };

        fetch(postUrl, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json, application/xml, text/plain, text/html, *.*',
            }),
        })
        .then(res => res.json())
        .then(() => {
            setSubmitted(true);
        })
        .catch(err => {
            alert('Error sending form. Please try again, if the problem persists, contact us.')
        });
    };

    return (
        <FormContainer>
            {didSubmit && <SuccessMessage><b>Thanks!</b> Your message has been sent.</SuccessMessage>}
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <Form id="ContactForm" onSubmit={handleSubmit}>
                
                <label>
                    <span>Email</span>
                    <input required type="email" name="email" placeholder={formEmailPlaceholder} onChange={(e) => setEmail(e.target.value)} value={email} />
                </label>
                <label>
                    <span>First Name</span>
                    <input type="text" name="firstname" placeholder={formFirstNamePlaceholder} onChange={(e) => setFirstName(e.target.value)} value={firstName} />
                </label>
                <label>
                    <span>Last Name</span>
                    <input type="text" name="lastname" placeholder={formLastNamePlaceholder} onChange={(e) => setLastName(e.target.value)} value={lastName} />
                </label>
                
                {/* <HiddenLabel>
                    <span>First Name</span>
                    <input readOnly type="name" name="firstName" value={getFirstName(name)} />
                </HiddenLabel>
                <HiddenLabel>
                    <span>Last Name</span>
                    <input readOnly type="name" name="lastName" value={getLastName(name)} />
                </HiddenLabel> */}

                <textarea required name="message" placeholder={formNotePlaceholder} rows={4} onChange={e => setMessage(e.target.value)} />
                {/* <select required name="budget">
                    <option value="" disabled selected>{formBudgetPlaceholder}</option>
                    {budgetOptions.map(o => (
                        <option key={o} value={o}>{o}</option>
                    ))}
                </select> */}
                <BoxButton>
                    {formSubmitButtonText}
                </BoxButton>
            </Form>
        </FormContainer>
    );
}