import React from 'react';
import styled from "@emotion/styled";

const Line = styled.div<any>`
  height: 1px;
  width: ${props => props.width};
  margin: ${props => props.margin};
  background-color: ${props => props.theme.borderColor};
`;

interface ISeparatorProps {
  width?: string,
  margin?: string,
}

export default function Separator(props: ISeparatorProps) {
  
  const options = {
    width: "146px",
    margin: "70px 0",
    ...props,
  }
  
  return (
    <Line {...options} />
  )
}