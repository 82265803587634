import React from 'react';
import styled from "@emotion/styled";

const Button = styled.button<any>`
  height: ${props => props.height};
  width: ${props => props.width};
  margin-top: ${props => props.marginTop};
  border: none;
  position: relative;
  font-family: ${props => props.theme.contentFontFamily};
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  color: #1E3961;
  transition: color .2s ease-in-out, background-color .2s ease-in-out;
  cursor: pointer;
  background-color: #fff;

  ::before {
      background: none;
      border: 1px solid #1E3961;
      content: " ";
      display: block;
      position: absolute;
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      pointer-events: none;
      transition: border-color .2s ease-in-out;
  }

  &:hover {
      color: #fff;
      background-color: #1E3961;

      ::before {
          border-color: #fff;
      }
  }
`;

interface IBoxButtonProps {
  height?: string,
  width?: string,
  marginTop?: string,
  children: string,
}

export default function BoxButton(props: IBoxButtonProps) {

  const options = {
    height: "90px",
    width: "200px",
    marginTop: "0",
    ...props,
  };

  const {
    height,
    width,
    marginTop,
  } = options;



  return (
    <Button height={height} width={width} marginTop={marginTop}>
      {props.children}
    </Button>
  );
}