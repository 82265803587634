import React from "react";
import styled from "@emotion/styled";

const ContactInfoBoxContainer = styled.div<any>`
    width: 100%;
    max-width: 325px;
    margin: 31px auto;

    @media (min-width: ${props => props.theme.lg}px) {
        width: 40%;
        margin: 0;
    }
`;

const ContactInfoBox = styled.div<any>`
    background: ${props => props.theme.primaryColor};
    padding: 10px;
`;

const ContactInfoBoxContent = styled.div<any>`
    text-align: center;
    padding: 28px;
    border: 1px solid #fff;
    height: 100%;
    color: #fff;
    font-size: 16px;
    line-height: 1.6;
`;

const ContactInfoTitle = styled.div<any>`
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 20px;
    font-family: ${props => props.theme.headerFontFamily};
`;

const ContactInfoEmail = styled.a<any>`
    display: block;
    margin-bottom: 20px;
    color: #fff;
    font-size: 18px;
`;

const ContactInfoLocation = styled.div<any>`
    font-size: 18px;
    line-height: 21px;
`;

const ContactInfoServing = styled.div<any>`
    font-size: 18px;
    line-height: 21px;
`;

export interface IContactInfoProps {
    title: string;
    email: string;
    location: string;
    serving: string;
}

export default function ContactInfo(props: IContactInfoProps): JSX.Element {
    const {
        title,
        email,
        location,
        serving
    } = props;

    return (
        <ContactInfoBoxContainer>
            <ContactInfoBox>
                <ContactInfoBoxContent>
                    <ContactInfoTitle>{title}</ContactInfoTitle>
                    <ContactInfoEmail href={`mailto:${email}`}>{email}</ContactInfoEmail>
                    <ContactInfoLocation>{location}</ContactInfoLocation>
                    <ContactInfoServing>{serving}</ContactInfoServing>
                </ContactInfoBoxContent>
            </ContactInfoBox>
        </ContactInfoBoxContainer>
    );
}